import { Link } from 'gatsby'
import React from 'react'
import { GENERIC_APK } from '../../download-links'
import * as links from '../../internal-links'
import './CategoryInfo.scss'
import ReadMoreLessSections from './ReadMoreLessSections'

const PoolInfo: React.FC = () => {
  const visible = (
    <>
      <h2 className="page-title">Learn more about GoPool</h2>
      <p>
        The table is set. The players are ready. Are you ready to be a part of
        the Pool club?
      </p>
      <p>
        8 ball Pool, or simply Pool, is the world’s most popular variant of
        pocket billiards games. It gets its name from the black ‘8’ ball that is
        required to finish the game.
      </p>
      <p>
        Aim the cue stick right. With the right speed and the right spin, pocket
        all the balls assigned. Remember to pocket the black ball at last.
        Sounds fun? What if we added the thrill of real money to 8 ball pool
        online, with the excitement of playing against real players?
      </p>
    </>
  )
  const hidden = (
    <>
      <p>
        Mega brings to you Go Pool, a thrilling multiplayer, real money
        adaptation of the elite 8 Ball Pool. Available for your Android device,
        this game features lifelike graphics, great physics and user-friendly
        controls. Go Pool gives you the chance to truly put your skills to use
        in a real money pool game.
      </p>
      <p>
        <Link to={GENERIC_APK}>Download Mega</Link>. Play GoPool. Compete with
        multiple players online in real time. Showcase your skills — pocket the
        balls, and the prize.
      </p>
      <p>
        Welcome to the club! Whether you are a recreational player looking for a
        light- hearted game of online pool or a true challenger searching for a
        real money Pool app, there is a pool table at Mega that perfectly suits
        you.
      </p>

      <p>
        Race to zero and win the pool. All players begin with 10 points. The aim
        of the game is to pocket the balls of your colour to reduce your points.
        The first player to reach ‘0’ wins.
      </p>
      <ul>
        <li>
          <p>
            Aim the cue stick. Pull back the meter and release to hit the cue
            (white) ball. The further you pull, the greater the power. You can
            also set the spin by tapping on the cue ball icon.
          </p>
        </li>
        <li>
          <p>
            Strike and pocket the balls of your colour. The colours are assigned
            on the basis of the first balls pocketed by the players. Each
            correct ball pocketed reduces the score by 1 point and provides one
            more turn.
          </p>
        </li>
        <li>
          <p>
            After potting all the balls of your colour, pot the black ball to
            reduce the points to zero.
          </p>
        </li>
        <li>
          <p>
            When a player wins i.e. reaches zero, the remaining player’s points
            are pooled together to determine the prize.
          </p>
        </li>
        <li>
          <p>
            Whenever a foul is committed, the next player can place the ball at
            their desired position. Fouls are committed if:
          </p>
          <ul>
            <li>
              <p>You directly hit your opponent’s ball.</p>
            </li>
            <li>
              <p>You pot the cue ball.</p>
            </li>
            <li>
              <p>No ball touches the Pool Table rail.</p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            Potting the black ball before potting all other balls of your colour
            throws you out of the game.
          </p>
        </li>
      </ul>

      <h3>Realistic Graphics, Smooth Controls</h3>
      <p>
        Go Pool features rich life-like graphics, with great physics and
        satisfying feedback response - so that you have the smoothest real money
        8 ball Pool experience on your Android device. With the user-friendly
        controls of this game, you can aim with accuracy and play with
        precision. So it’s truly your skills that decide who sweeps the pool and
        earns real money.
      </p>

      <h3>A table for everyone</h3>
      <p>
        Are you a casual player looking for a fun Pool game or a challenger at
        the club earning money with 8 ball Pool? We have you covered either way.
        With entry at Pool tables as low as ₹1, we’ve made sure there’s a table
        that suits you. Aim. Shoot. Pocket. Repeat.
      </p>

      <h3>Verified players</h3>
      <p>
        Mega allows only 100% verified profiles of verified age. The games are
        played in multiplayer mode in real time, against 2 or more players. No
        bots, no tricks. Only real money pool on the Mega app.
      </p>

      <h3>Real Money, instant withdrawals</h3>
      <p>
        Winnings of the game are added to your winnings wallet just as you leave
        the Pool table. The winnings can be seamlessly withdrawn through UPI, or
        directly to your bank account or your PayTM wallet, all within a few
        minutes.
      </p>

      <h3>24x7 Support</h3>
      <p>
        Got a query or a game play issue? Our support team is available round
        the clock to resolve any queries regarding the games, withdrawals or any
        other related issues. Just message us on the chat, and we shall resolve
        it at the earliest.
      </p>
    </>
  )
  return (
    <div className="category-info">
      <ReadMoreLessSections
        visible={visible}
        hidden={hidden}
        gameName="GoPool"
        blogLink={links.POOL_LEARN}
        blogLabel="Learn Pool"
      />
    </div>
  )
}

export default PoolInfo
