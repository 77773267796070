import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import PoolPage from '../../../components/Games/Casual/Pool/Pool'
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs'
import {
  CASUAL,
  GAMES,
  POOL as POOL_LINK,
} from '../../../components/internal-links'
import { POOL } from '../../../components/download-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generatePokerReviewSchemaAndroid,
  generatePokerReviewSchemaIos,
  generateWebsiteSchema,
} from '../../../lib/generate-schema'
import { Faq } from '../../../components/FAQs/faqs-list-interface'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Games',
    url: GAMES,
  },
  {
    title: 'Casual',
    url: CASUAL,
  },
  {
    title: 'GoPool',
    url: POOL_LINK,
  },
]

const Pool: React.FC = () => {
  const [poolFaqsSt, setPoolFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../../components/FAQs/faqs-list').then(({ poolFaqs }) =>
      setPoolFaqsSt(poolFaqs)
    )
  }, [])

  return (
    <Layout showBreadcrumbs breadcrumbs={breadcrumbs} apkLink={POOL}>
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="GoPool: Play 8 (Eight) Ball Pool for Real Money Online and Win Cash"
        description="Play Go Pool, the perfect multiplayer version of real money 8 ball pool. Download Mega, beat your opponents with your aiming skills, & win exciting real cash prizes."
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        reviewSchemaPokerAndroid={generatePokerReviewSchemaAndroid()}
        reviewSchemaPokerIos={generatePokerReviewSchemaIos()}
        faqSchema={generateFaqSchema(poolFaqsSt)}
      />
      <PoolPage />
    </Layout>
  )
}

export default Pool
